import React from 'react'
import Layout from '../components/layout'
import ProjectManager from '../components/categories/project-manager'
import Seo from '../components/seo'

const ProjectManagerPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ProjectManager />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Project Manager Freelancers | Codestaff'
  const description =
    'Hire the best Project Manager freelancers at Codestaff. Get the top 1% of Project Manager professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default ProjectManagerPage
